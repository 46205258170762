import React from 'react';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import About from './components/About';
import Product from './components/Product';
import Research from './components/Research';
import Contact from './components/Contact';
import CustomerQueue from './components/CustomerQueue';
import './index.css';

function App() {
  return (
    <div className="App">
      <Header />
      <HeroSection />
      <About />
      <Product />
      <Research />
      <Contact />
      <CustomerQueue />
    </div>
  );
}

export default App;
