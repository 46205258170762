import React from 'react';

function About() {
  return (
    <div className="section">
      <h2>À Propos de GravitoQ</h2>
      <p>Née d'une ambition sans limite, GravitoQ repousse les frontières de la science pour offrir une solution révolutionnaire à l'humanité. Notre suppositoire quantique, enrichi d'une intelligence artificielle sur-mesure, est conçu pour améliorer votre bien-être de manière inédite. GravitoQ, c'est la promesse d'une santé optimisée, protégée des menaces modernes comme les ondes néfastes de la 6G.</p>
      <p>Avec GravitoQ, préparez-vous à vivre une expérience transcendantale, où bien-être et technologie ne font qu'un. Rejoignez-nous dans cette aventure, où chaque détail compte, jusqu'au dernier quantum.</p>
    </div>
  );
}

export default About;
