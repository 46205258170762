import React from 'react';
import quantumSuppositoryImage from '../img/suppository.png';

function Product() {
  return (
    <div className="section flex-container">
      <div className="flex-item-image">
        <img src={quantumSuppositoryImage} alt="Quantum Suppository" />
      </div>
      <div className="flex-item-text section-content">
        <h2>Notre Produit: Le Suppositoire Quantique</h2>
        <p>Au cœur de notre révolution technologique se trouve le Suppositoire Quantique GravitoQ, une prouesse
          d'ingénierie qui intègre l'IA et la mécanique quantique pour offrir une solution de bien-être avant-gardiste.
          Ce
          dispositif, conçu pour une administration aisée et discrète, est le premier du genre à harmoniser votre
          biorythme avec les fluctuations quantiques de l'environnement, offrant ainsi une protection sans précédent
          contre les perturbations modernes, y compris la redoutée 6G.</p>
        <p>Grâce à son revêtement biomimétique et à son IA intégrée, le Suppositoire Quantique adapte son action en
          temps
          réel, assurant un équilibre parfaitement synchronisé avec vos besoins physiologiques uniques. Découvrez le
          futur
          du bien-être - un futur où chaque jour est une aventure quantique.</p>
      </div>
    </div>
  );
}

export default Product;
