import React from 'react';

function HeroSection() {
  return (
    <div className="hero-section">
      <h1>Révolutionnez votre bien-être avec GravitoQ</h1>
      <p>L'avant-garde de la santé quantique et de l'IA, au service de l'humanité. Découvrez comment nous prévoyons de vous protéger contre la 6G avant même qu'elle n'existe.</p>
      <button>Explorez la fusion parfaite entre la médecine ancestrale et les avancées de la pointe de l'intelligence artificielle.</button>
    </div>
  );
}

export default HeroSection;
