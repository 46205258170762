import React from 'react';
import contactFormImage from '../img/contact.png';

function Contact() {
  return (
    <div className="section flex-container">
      <div className="flex-item-image">
        <img src={ contactFormImage } alt="Contact Form" />
      </div>
      <div className="flex-item-text section-content">
        <h2>Contactez-nous</h2>
        <p>Vous êtes prêts à investir dans votre futur avec GravitoQ ? Notre équipe est là pour répondre à toutes vos
          questions, vous guider dans le monde fascinant de la technologie quantique, et vous accueillir parmi nos
          clients visionnaires. Ensemble, explorons les possibilités infinies offertes par notre suppositoire
          quantique.</p>
        <p>Pour plus d'informations, pour rejoindre notre programme de beta-testeurs, ou simplement pour nous faire part
          de vos impressions, n'hésitez pas à nous contacter via instagram ou TikTok.
          L'avenir vous appartient - saisissez-le avec GravitoQ.</p>
      </div>
    </div>
  );
}

export default Contact;
