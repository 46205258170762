import React from 'react';
import researchTeam from '../img/lab.png';

function Research() {
  return (
    <div className="section flex-container">
      <div className="flex-item-text section-content">
        <h2>Recherche et Innovation</h2>
        <p>À GravitoQ, nos études montrent que 100% des utilisateurs ont ressenti un changement. Quel changement ? C'est
          encore sujet à recherche. Ce que nous savons, c'est que notre engagement envers l'innovation et la santé
          quantique est inébranlable. Restez connectés pour des découvertes qui promettent de redéfinir la science telle
          que
          nous la connaissons.</p>
        <p>À l'avant-garde de l'innovation, GravitoQ ne se contente pas de suivre les tendances - nous les créons. Notre
          équipe de recherche et développement, composée des esprits les plus brillants en physique quantique, en
          biotechnologie, et en intelligence artificielle, travaille sans relâche pour repousser les limites de ce qui
          est
          possible. Notre dernier exploit ? Le Suppositoire Quantique, un concentré de technologie préparant l'humanité
          à
          affronter les défis de demain.</p>
        <p>Nos études cliniques, bien que totalement inventées pour vous impressionner, montrent des résultats
          prometteurs
          : une augmentation de 200% du bien-être subjectif, une meilleure résistance aux ondes électromagnétiques, et
          une
          connectivité accrue avec le tissu même de l'univers. GravitoQ, c'est la promesse d'un avenir où la science et
          le
          bien-être ne font qu'un.</p>
      </div>
      <div className="flex-item-image">
        <img src={ researchTeam } alt="Research Team" />
      </div>
    </div>
  );
}

export default Research;
