import React from 'react';
import customerQueueImage from '../img/desk.png';

function CustomerQueue() {
  return (
    <div className="section flex-container">
      <div className="flex-item-text section-content">
        <h2>Rejoignez la Révolution GravitoQ</h2>
        <p>La scène est vivante, presque électrique, alors que des personnes de tous horizons se pressent avec impatience. Chacun partage un même but : découvrir le pouvoir transformateur du Suppositoire Quantique GravitoQ. Entre rires partagés et discussions animées sur les promesses de la technologie quantique, l'atmosphère est imprégnée d'un mélange unique d'excitation et de curiosité. Cet engouement n'est pas juste pour un produit, mais pour une révolution dans la manière dont nous envisageons le bien-être et la santé à l'ère technologique. GravitoQ n'est pas seulement accueilli ; il est célébré comme le porte-étendard d'un avenir où la santé est à la fois une science et une aventure.</p>
      </div>
      <div className="flex-item-image">
        <img src={ customerQueueImage } alt="Enthusiastic Customer Queue" />
      </div>
    </div>
  );
}

export default CustomerQueue;
