import React from 'react';

function Header() {
  return (
    <header>
      <div className="header-container">
        <div className="logo">GravitoQ</div>
        <nav>
          <ul className="nav-links">
            <li><a href="#about">À Propos</a></li>
            <li><a href="#product">Produit</a></li>
            <li><a href="#research">Recherche</a></li>
            <li><a href="#contact">Contact</a></li>
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Header;
